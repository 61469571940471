import { NgModule } from '@angular/core';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from '@shared/shared.module';
import { HeaderSharedDataService } from '@shared/components/header/header-shared-data.service';
import { DashboardSharedDataService } from './dashboard-shared-data.service';
import { ComponentsProdutoModule } from '@modules/produto/components/components-produto.module';
import { ComponentsRamoModule } from '@modules/ramo/components/components-ramo.module';
import { ProdutoRamoComponent } from './pages/produto-ramo/produto-ramo.component';
import { ModalAlertaAposLoginComponent } from './components/modal-alerta-apos-login/modal-alerta-apos-login.component';
import { JoyrideModule } from 'ngx-joyride';
import { DashCarrouselComponent } from './components/dash-carrousel/dash-carrousel.component';
import { DashNewsComponent } from './components/dash-news/dash-news.component';
import { DashUpdatesComponent } from './components/dash-updates/dash-updates.component';
import { DashRecentActivityComponent } from './components/dash-recent-activity/dash-recent-activity.component';
import { DashFavoritesComponent } from './components/dash-favorites/dash-favorites.component';
import { DashContainerPropComponent } from './components/dash-container-prop/dash-container-prop.component';
import { CnsegUpdatesComponent, UpdateFourComponent, UpdateOneComponent, UpdateThreeComponent, UpdateTwoComponent } from './pages/cnseg-updates/cnseg-updates.component';
import { RnpaPessoasModule } from '@modules/consulta/produtos/rnpa-pessoas/rnpa-pessoas.module';

@NgModule({
    declarations: [
        DashboardComponent,
        ProdutoRamoComponent,
        ModalAlertaAposLoginComponent,
        DashCarrouselComponent,
        DashNewsComponent,
        DashUpdatesComponent,
        DashRecentActivityComponent,
        DashFavoritesComponent,
        DashContainerPropComponent,
        CnsegUpdatesComponent,
        UpdateOneComponent,
        UpdateTwoComponent,
        UpdateThreeComponent,
        UpdateFourComponent,
    ],
    imports: [
        SharedModule,
        DashboardRoutingModule,
        ComponentsProdutoModule,
        ComponentsRamoModule,
        JoyrideModule.forRoot(),
        RnpaPessoasModule
    ],
    providers: [
        HeaderSharedDataService,
        DashboardSharedDataService,
    ]
})
export class DashboardModule { }