import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { environment } from '@env/environment';
import { ContatoComponent } from '@modules/contato/contato.component';
import { LoginComponent } from '@modules/login/pages/login.component';
import { SobreComponent } from '@modules/sobre/sobre.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScrollDirective } from '@shared/directives/scroll/scroll.directive';
import { Observable } from 'rxjs';
import { AppSharedDataService } from './app-shared-data.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'portal-app';
  isLoggedIn$: Observable<boolean>;
  @ViewChild('principal', { read: ViewContainerRef, static: false }) principal: ViewContainerRef;
  positionScroll: any;
  versao: string;
  menuConfigActive: boolean = false;
  isMenuLoaded: boolean = false

  constructor(private authenticationService: AuthenticationService,
    private router: Router, private modalService: NgbModal, private gtmService: GoogleTagManagerService,
    private appSharedDataService: AppSharedDataService, private localStorageService: LocalStorageService) {
    console.info(`App Version: ${environment.version}`);
    this.isLoggedIn$ = this.authenticationService.isLoggedIn;

  }

  onMenuToogle(): void {

  }

  ngOnInit(): void {
    this.versao = environment.version;
    this.appSharedDataService.configuracaoMenu$.subscribe((res) => this.menuConfigActive = res)
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      this.addRecentActivity(event)

      if (event instanceof NavigationEnd) {
        if (event.url.includes('dashboard')) {
          this.principal.element.nativeElement.scrollTo(0, this.positionScroll);
          this.tagManagerPushEvent(event);
        }
      } else {
        this.tagManagerPushEvent(event);
      }
    })
  }

  tagManagerPushEvent(event): void {
    const gtmTag = {
      event: 'page',
      pageName: event.url
    };

    this.gtmService.pushTag(gtmTag);
  }

  openSobre(): void {
    this.modalService.open(SobreComponent, {
      keyboard: false,
      beforeDismiss: () => {
        return this.authenticationService.currentUserValue == null;
      },
      centered: true
    });
  }

  openContato(): void {
    this.modalService.open(ContatoComponent, {
      keyboard: false,
      beforeDismiss: () => {
        return this.authenticationService.currentUserValue == null;
      },
      centered: true
    });
  }
  onActivate(component: Component) {
    this.isMenuLoaded = !(component instanceof LoginComponent)
  }

  addRecentActivity(event): void {
    if (!event.url || !(event instanceof NavigationStart) || !event.url.includes('/consulta/')) return

    let recentActivity: { historico: { date: Date, url: string }[] } = JSON.parse(this.localStorageService.getItem('atividade-recente')) || null

    const activityContext: { date: Date, url: string } = { date: new Date(), url: event.url }
    const urls = recentActivity ? [...recentActivity.historico.map(e => e.url)] : []

    // Se não possui atividade no histórico
    if (!recentActivity) {
      const rawActivity = {
        historico: [
          activityContext
        ]
      }
      this.localStorageService.setItem('atividade-recente', JSON.stringify(rawActivity));
    // Se possui atividade, porém menor ou igual a 10 e não existe valor igual no array
    } else if (recentActivity.historico.length <= 10 && !urls.includes(activityContext.url)) {
      recentActivity.historico.unshift(activityContext)
      this.localStorageService.setItem('atividade-recente', JSON.stringify(recentActivity));
    } 
    
    // Se possui atividade com mesma url, deve remover a atividade e atribuí-la ao topo da lista
    if(urls.includes(activityContext.url)){
      const itemIndex = urls.indexOf(activityContext.url)
      recentActivity.historico.splice(itemIndex, 1)
      recentActivity.historico.unshift(activityContext)
      this.localStorageService.setItem('atividade-recente', JSON.stringify(recentActivity));
    }
  }
}