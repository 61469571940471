import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '@core/services/pdf.service';

@Component({
  selector: 'app-cnseg-updates',
  templateUrl: './cnseg-updates.component.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class CnsegUpdatesComponent implements OnInit {

  updateTemplates: {index: number, title: string}[] = [{
    index: 1,
    title: 'Melhoria na tela de envio de lotes',
  },
  {
    index: 2,
    title: 'Nova tabela de valores 2024 CNSeg',
  },
  {
    index: 3,
    title: 'Nova Versão do Regulamento de Soluções Tecnológicas',
  },
  {
    index: 4,
    title: 'Nova tabela de valores 2024 FENASEG',
  }]

  currentUpdateIndex: number = 1

  constructor(private _pdfService: PdfService, private _activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe((params) => {
      if(params.id){
        this.currentUpdateIndex = parseInt(params.id)
      }
    })
  }

  exportPDF() {
    this._pdfService.beforeCanvas('updateContainer');
    window.onafterprint = () => {
      this._pdfService.afterCanvas('updateContainer');
    }
    window.print();
  }

}



// Componentes HTML

@Component({
  selector: 'app-update-one',
  templateUrl: './templates/first-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateOneComponent {
}

@Component({
  selector: 'app-update-two',
  templateUrl: './templates/second-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateTwoComponent {
}

@Component({
  selector: 'app-update-three',
  templateUrl: './templates/third-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateThreeComponent {
}

@Component({
  selector: 'app-update-four',
  templateUrl: './templates/fourth-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateFourComponent {
}

