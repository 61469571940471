import { ToastrModule } from 'ngx-toastr';

import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CurrencyMaskModule } from "ng2-currency-mask";

import { HeaderComponent } from '@shared/components/header/header.component';
import { AppSharedDataService } from './app-shared-data.service';
import { SobreModule } from './modules/sobre/sobre.module';
import { TabsModule } from './modules/tabs/tabs.module';
import { NotificacaoModule } from './modules/notificacao/notificacao.module';
import { TrocarSenhaModule } from './modules/trocar-senha/trocar-senha.module';
import { ContatoModule } from './modules/contato/contato.module';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './custom-reuse-strategy';
import { NgxPermissionsService } from 'ngx-permissions';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { FavoritoModule } from '@modules/favorito/favorito.module';
import { DashboardModule } from '@modules/dashboard/dashboard.module';
import { JoyrideModule } from 'ngx-joyride';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@NgModule({
  declarations: [
    HeaderComponent,
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    CurrencyMaskModule,
    SobreModule,
    TabsModule,
    NotificacaoModule,
    TrocarSenhaModule,
    ContatoModule,
    FavoritoModule,
    DashboardModule,
    JoyrideModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    AppSharedDataService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadPermissions,
      deps: [NgxPermissionsService, LocalStorageService],
      multi: true
    },
    {
      provide: 'googleTagManagerId',
      useValue: "GTM-K96RGVJ"
    },
    GoogleTagManagerService
  ]
})
export class AppModule { }

export function loadPermissions(rs: NgxPermissionsService, localStorageService: LocalStorageService) {
  return () => {
    let userData = JSON.parse(localStorageService.getItem('currentUser'));
    if (userData && userData.funcionalidades && Array.isArray(userData.funcionalidades))
      rs.loadPermissions(userData.funcionalidades);
  };
}